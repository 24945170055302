import request from '@/utils/oms-request'

// 绩效字段分页查询
export function getPerformanceFieldByPage(params) {
  return request({
    url: `oms/performance/getPerformanceFieldByPage`,
    method: 'get',
    params
  })
}
// 更新绩效字段
export function updatePerformanceField(data) {
  return request({
    url: `oms/performance/updatePerformanceField`,
    method: 'post',
    data
  })
}
// 批量新增绩效字段
export function insertPerformanceField(data) {
  return request({
    url: `oms/performance/insertPerformanceField`,
    method: 'post',
    data
  })
}
// 校验绩效字段名是否存在
export function checkPerformanceFieldName(params) {
  return request({
    url: `oms/performance/checkPerformanceFieldName`,
    method: 'get',
    params
  })
}
// 绩效规则分页查询
export function getPerformanceRuleByPage(params) {
  return request({
    url: `oms/performance/getPerformanceRuleByPage`,
    method: 'get',
    params
  })
}
// 禁用绩效规则
export function unablePerformanceRule(params) {
  return request({
    url: `oms/performance/unablePerformanceRule`,
    method: 'get',
    params
  })
}
// 添加绩效规则
export function insertPerformanceRule(data) {
  return request({
    url: `oms/performance/insertPerformanceRule`,
    method: 'post',
    data
  })
}
// 校验绩效规则名是否存在
export function checkPerformanceRuleName(params) {
  return request({
    url: `oms/performance/checkPerformanceRuleName`,
    method: 'get',
    params
  })
}
// 查询绩效字段
export function getPerformanceFieldByList(params) {
  return request({
    url: `oms/performance/getPerformanceFieldByList`,
    method: 'get',
    params
  })
}
// 查询绩效规则详情
export function getPerformanceRuleDetail(params) {
  return request({
    url: `oms/performance/getPerformanceRuleDetail`,
    method: 'get',
    params
  })
}
// 获取分数排名
export function getScoreRank(params) {
  return request({
    url: `oms/performance/getScoreRank`,
    method: 'get',
    params
  })
}
// 导出分数排名
export function exportScoreRank(params) {
  return request({
    url: `oms/performance/exportScoreRank`,
    method: 'get',
    params
  })
}
// 导出分数排名
export function getScoreByCycle(params) {
  return request({
    url: `oms/performance/getScoreByCycle`,
    method: 'get',
    params
  })
}
// 根据周期查询绩效分数(Map形式返回)
export function getScoreByCycleByMap(params) {
  return request({
    url: `oms/performance/getScoreByCycleByMap`,
    method: 'get',
    params
  })
}
// 获取客服部门员工信息
export function getEmployee(params) {
  return request({
    url: `oms/performance/getEmployee`,
    method: 'get',
    params
  })
}
// 获取客服部门员工信息
export function getFieldDetailByRuleId(params) {
  return request({
    url: `oms/performance/getFieldDetail0ByRuleId`,
    method: 'get',
    params
  })
}
// 自定义字段分数录入
export function addFieldScore(data) {
  return request({
    url: `oms/performance/addFieldScore`,
    method: 'post',
    data
  })
}
// 获取日期范围内具体的周期范围
export function getCycleByDateRange(params) {
  return request({
    url: `oms/performance/getCycleByDateRange`,
    method: 'get',
    params
  })
}
